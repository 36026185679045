import styled from "styled-components";

export const RedButton = styled.button.attrs((props: {bg_color: string,}) => props)`
  background: ${(props) => props.bg_color};
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
  height: 46px;
  width: 282px;
  cursor: pointer;
  padding: 12px 0px;
  font-weight: 600;

  @media (max-width: 600px) {
    width: 355px !important;
  }

  @media (max-width: 370px) {
    width: 282px !important;
  }

  @media (max-width: 311px) {
    width: 252px !important;
  }
`;


export const GrayButton = styled.button.attrs((props: {bg_color: string,}) => props)`
  background: ${(props) => props.bg_color};
  border: 1px solid #EBEBF0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #636366;
  width: 100%;
  height: 46px;
  max-width: 282px;
  cursor: pointer;
  padding: 12px 0px;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 13px;
    font-weight: 500;
    max-width: 355px;
  }
  @media (max-width: 600px) {
    width: 355px !important;
  }

  @media (max-width: 370px) {
    width: 282px !important;
  }

  @media (max-width: 311px) {
    width: 252px !important;
  }
`;

