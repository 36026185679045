/* eslint-disable react-hooks/exhaustive-deps */
import {
  accountLookUp,
  banksWithAccountNumber,
  banksWithUssd,
} from "../../../api/service";
import { usePopup } from "../../../context";
import { EventName, postEvent } from "../../../dropin/events";
import { useInitialisePayment } from "../../../hooks";
import {
  BankContainer,
  BankSelectWrapper,
  BankText,
  NameDisplay,
  WarningText,
} from "../../bank";
import { AppButton } from "../../components/app-button";
import { Spacer } from "../../components/spacer";
import { StandardAppWidth } from "../../ussd";
import { Checkbox, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "../../spinner";
import { Store } from "react-notifications-component";
import styled from "styled-components";

const InputField = styled.input`
  box-sizing: border-box;
  padding-left: 14px;
  font-size: 14px;
  background-color: #f8f8fc;
  color: #000000;
  border-color: #f8f8fc;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 52px;
  border: none;
  border-width: 0px;
  border-style: 0;

  &:focus {
    border-color: #f8f8fc;
  }

  &::placeholder {
    color: #b7b7b9;
    margin-left: 40px;
  }
`;

export const SelectBankSection = ({ ussdBanks }: { ussdBanks?: boolean }) => {
  const {
    banks,
    setSelectedBank,
    setPinValue,
    setIsBankSet,
    selectedBank,
    pinValue,
    setMerchantDetails,
    options: { txn_charge, txn_charge_type, key },
    setIsTransactionStarted,
    setCustomerName
  } = usePopup();

  const [lookupLoading, setLookupLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountDetailsContent, setAccountDetailsContent] = useState(false);

  const { loading, onClick } = useInitialisePayment("bank", setMerchantDetails);
  const [accountNo, setAccountNo] = useState("");

  const Onclick = () => {

 
    if (selectedBank && accountNo!.length === 10) {
      if (!accountDetailsContent) {
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: "Please agree to continue.",
          type: "danger",
          dismiss: {
            duration: 2000,
          },
        });
        return;
      }
      if (
        !txn_charge_type &&
        (txn_charge === null || txn_charge === undefined)
      ) {
        setIsTransactionStarted!(true);
        onClick();
      } else {
        //Transfer
        setIsTransactionStarted!(true);
        setIsBankSet!(true);
      }
    }
  };

  const onCancel = () => {
    postEvent({
      target: window.parent,
      event: {
        name: EventName.EXIT,
        payload: {
          message: "User exited the flow",
          code: "04",
        },
      },
    });
  };

  const nubanLookup = useCallback(async () => {
    setPinValue!(accountNo);

    const data = {
      account_number: accountNo,
      bank_code: selectedBank[0]?.code,
    };
    try {
      setLookupLoading(true);
      const res = await accountLookUp(key, data);
      setLookupLoading(false);
      if (res.data.accountname) {
        setAccountName(res?.data?.accountname);
        setCustomerName!(res?.data?.accountname)
      } else {
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: res?.data?.message,
          type: "danger",
          dismiss: {
            duration: 2000,
          },
        });
      }
    } catch (error) {
      setLookupLoading(false);
      Store.addNotification({
        insert: "top",
        container: "top-right",
        message: "Error fetching account name. Please try again.",
        type: "danger",
        dismiss: {
          duration: 2000,
        },
      });
    }
  }, [selectedBank, accountNo, pinValue]);

  useEffect(() => {
    if (accountNo?.length === 10 && selectedBank[0]?.code) {
      nubanLookup();
    }

    return () => {
      setLookupLoading(false);
      setAccountName("");
      setCustomerName!("")
    };
  }, [nubanLookup, accountNo?.length]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      return;
    }

    // Allow delete and backspace keys
    if (event.key === "Delete" || event.key === "Backspace") {
      return;
    }

    // Prevent the input if not a number
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNo!(event.target.value);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    //prevent pasting of texts
    const pastedValue = event.clipboardData
      .getData("text/plain")
      .replace(/\D/g, ""); // Extract numeric value from the pasted text
    const sanitizedValue = pastedValue.slice(0, 10); // Limit the pasted input to 10 digits
    
    // Insert the sanitized value into the input field
    //@ts-ignore
    event.target.value = sanitizedValue;

    event.preventDefault(); /// Prevent the default paste behavior
    if(sanitizedValue.length === 10 && selectedBank[0]?.code){
      setAccountNo(sanitizedValue)
    }
  };

  return (
    <BankSelectWrapper>
      <BankText style={{ fontSize: "13px" }}>
      Enter account details you are paying from
      </BankText>
      <Spacer height={15} />
      <StandardAppWidth>
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: "14px", color: "#B7B7B9", fontWeight: "400" }}
          >
            Select your bank
          </InputLabel>
          <Select
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            onChange={(e: { target: { value: string } }) =>
              setSelectedBank!([JSON.parse(e?.target?.value)])
            }
            style={{
              backgroundColor: "#F8F8FC",
              borderRadius: "4px",
              height: "52px",
            }}
          >
            {ussdBanks &&
              banksWithUssd(banks).map(
                (bank: { code: string; name: string }) => (
                  <MenuItem value={`${JSON.stringify(bank)}`}>
                    {bank.name}
                  </MenuItem>
                )
              )}
            {!ussdBanks &&
              banksWithAccountNumber(banks).map(
                (bank: { code: string; name: string }) => (
                  <MenuItem value={`${JSON.stringify(bank)}`}>
                    {bank.name}
                  </MenuItem>
                )
              )}
          </Select>
        </FormControl>
      </StandardAppWidth>
      <Spacer height={20} />
      <BankContainer>
        {lookupLoading && (
          <>
            <Spinner />
          </>
        )}

        <StandardAppWidth>
          <InputField
            type="text"
            inputMode="numeric"
            onKeyDown={handleKeyDown}
            placeholder="Enter account number"
            maxLength={10}
            onChange={handleChange}
            onPaste={handlePaste}
          />
        </StandardAppWidth>
        {accountName?.trim() !== "" && (
          <>
            <Spacer height={10} />
            <StandardAppWidth>
              <NameDisplay
              >
                {accountName}
              </NameDisplay>
            </StandardAppWidth>
            <Spacer height={30} />
            <StandardAppWidth>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginBottom: "15px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  columnGap: "3px",
                  boxSizing: "border-box",
                }}
              >
                <Checkbox
                  style={{ padding: 0 }}
                  sx={{
                    color: "#EBEBF0",
                    "&.Mui-checked": {
                      color: "red",
                    },
                  }}
                  checked={accountDetailsContent}
                  onChange={() => setAccountDetailsContent((prev) => !prev)}
                />
                <BankText
                  style={{
                    color: "#48484A",
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight:"16px"
                  }}
                >
                  Please confirm this is the sender’s account. It will be used for transaction verification.
                </BankText>
              </div>
            </StandardAppWidth>
          </>
        )}

        <Spacer height={20} />

        <WarningText>Virtual accounts are not supported </WarningText>
      </BankContainer>
      <Spacer height={31} />
      <div style={{ width: "" }}>
        <AppButton
          type="redBtn"
          disabled={
            accountNo?.length !== 10 ||
            !selectedBank[0] ||
            !accountDetailsContent
          }
          onClick={() => Onclick()}
          // onClick={() => {
          //   setIsTransactionStarted!(true);
          //   if (customClick) {
          //     customClick();
          //   } else {
          //     onClick();
          //   }
          // }}
          isBusy={!txn_charge_type && !txn_charge ? loading : false}
          name="Continue"
        />
      </div>

      {/* <Spacer height={15} /> */}
      {/* <UnstyledButton onClick={() => onCancel()}>Cancel</UnstyledButton> */}
    </BankSelectWrapper>
  );
};
