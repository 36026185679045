import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import bank from "../assets/bank.svg";
import bank2 from "../assets/bank2.svg";
import ussd from "../assets/call.svg";
import credit2 from "../assets/Call2.svg";
import scan from "../assets/scan.svg";
import scan2 from "../assets/scan2.svg";
import credit from "../assets/credit-card.svg";
import ussd2 from "../assets/credit.svg";
import vpayLogo from "../assets/vlogo.svg";

import { usePopup } from "../context";
import { inboundDownTimeCheck, numberWithCommas } from "../api/service";
import { useCalculatedAmount } from "../hooks";
import useMediaQuery from "./components/use-mediaquery";
import { Spacer } from "./components/spacer";

export const PopupNav = ({ setPosition, position, setShowCore }: any) => {
  const {
    isSuccess,
    isTimeLapsed,
    setIsModalOpen,
    setCurrentIndex,
    options,
    isFailed,
    isRefUsed,
    isTransactionStarted,
    setIsBankSet,
    merchantDetails: { account_name },
    setIsDowntime,
  } = usePopup();
  const { AmountToShow } = useCalculatedAmount();
  const matches = useMediaQuery("(max-width: 600px)");
  const pRef = useRef<HTMLDivElement | any>(position);
  const [isChecking, setIsChecking] = useState(false)

  const { channels = [ "bank", "ussd", 
  "card"
], key} = options;

  const [downtime, setDowntime] = useState({});

  const onClick = (index: number, channel: string) => {
    if (isSuccess || isTimeLapsed || isFailed || isRefUsed) {
      return;
    }
    if (isTransactionStarted) {
      setIsModalOpen!(true);
      return;
    }
    setIsBankSet!(false);
    pRef.current = index;
    setCurrentIndex!(pRef.current);
    setPosition(index);
    setShowCore(true);
  };

  const nameMap = (channel: string) => {
    switch (channel) {
      case "bank":
        return "Bank Transfer";
      case "ussd":
        return "USSD";
      case "card":
        return "Card";
      case "scan":
        return "N-QR Code";
      default:
        return "";
    }
  };
  const imageMap = {
    bank,
    bank2,
    ussd,
    ussd2,
    card: credit,
    card2: credit2,
    scan,
    scan2,
  };


  const downtimeCheck = useCallback(async () => {
  
    try {
      setIsChecking(true)
      const res = await inboundDownTimeCheck(key);

      setDowntime(res?.data?.data);
      setIsDowntime!(res?.data?.data);
      setIsChecking(false)


    } catch (error) {
      setIsChecking(false)
      console.log(error,"ERROR FETCHING DOWNTIME STATUS")
    }
  }, []);



  useEffect(() => {   
    (async function(){
     await downtimeCheck();
    })()
  }, [downtimeCheck])


  const renderChannel = (channel: any) => {
    const isDisabled =
      (channel === 'bank' && downtime!['web.eft']) ||
      (channel === 'card' && downtime!['web.card']) ||
      (channel === 'ussd' && downtime!['web.ussd']) || isChecking
  
    if (!isDisabled) {
      return (
        <NavItem key={channel} onClick={() => onClick(channels.indexOf(channel), channel)}>
          <CardNav className={position === channels.indexOf(channel) ? 'active' : ''}>
            <Image className="desktop-only" height="20px" width="20px" src={imageMap[channel]} alt={channel} />
            <Image className="mobile-only" height="20px" width="24px" src={imageMap[`${channel}2`]} alt={channel} />
            <CardText>{nameMap(channel)}</CardText>
          </CardNav>
        </NavItem>
      );
    }
    
    return null;
  };
  return (
    <CheckoutNav style={{width:`${matches && '100vw'}` }}>
      <NavWelcomeMessage className="desktop-only">
        <Wmessage>Pay With</Wmessage>
      </NavWelcomeMessage>

      <NavTextMsg style={{display:`${!matches && 'none'}` }} className="mobile-only">
        <Image 
          height="28px"
          width="70px"
          // src={options?.customer_logo ? options?.customer_logo : vpayLogo}
          src={vpayLogo}
        />
        <Spacer height='13px' />
        <div>
          Use one of the payment methods below to pay{" "}
          <span style={{ color: "#12B76A" }}>
            {" "}
            {options?.currency ?? "NGN"} {numberWithCommas(AmountToShow)}{" "}
          </span>{" "}
          to {" "}
          {account_name}
        </div>
      </NavTextMsg>
      <Spacer height='32px' className="mobile-only" />
      <NavList>
        {channels.map((channel: any) => renderChannel(channel))}
      </NavList>
      
      {/* <NavAction onClick={() => setShowCore(false)} className="mobile-only">
        Change
      </NavAction> */}
      {options.domain === "sandbox" && (
        <EnvironmentBadge>{"Mode: " + options.domain}</EnvironmentBadge>
      )}
    </CheckoutNav>
  );
};


export const CheckoutNav = styled.nav`
  width: 172px;
  // @media (min-width: 600px) {
  //   padding: 0px 0 0px 0px;
  // }

  // @media (max-width: 600px) {
  //   -webkit-box-flex: 0;
  //   -ms-flex: none;
  //   flex: none;
  //   position: relative;
  //   padding: 1px 20px;
  //   background: #ffffff;
  // }
`;

export const NavWelcomeMessage = styled.div`
  padding-left: 21px;
  height: 68px;
  display: flex;
  align-items: center;
`;

export const Wmessage = styled.h3`
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
`;

export const NavList = styled.div`
  list-style: none;
  padding: 0;
`;

export const NavItem = styled.li`
  padding-left: 21px;


  &:first-child {
    border-top: 0.5px solid rgba(255, 255, 255, 0.6);
  }
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
  position: relative;

  &.active::before {
    opacity: 1;
  }

  @media (max-width: 600px) {
   padding-left: 0px;
   display: flex;
   flex-direction: column;
   margin-top: 3px;
  }
`;

export const CardNav = styled.a`
  display: -webkit-box;
  display: -ms-flexbox;
  align-items: center;
  column-gap: 10px;
  display: flex;
  padding: 20px 0px 20px 0px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: -0.1px;
  cursor: pointer;
  position: relative;

  @media (max-width: 600px) {
    color: black;
    padding: 0px 20px 0 20px;
    height: 68px;
    background: #F8F8FC;
    

    &.active {
      // display: flex;
      // background: #8080802e;
      // border-radius: 8px;
    }

    &.active::after {
      display: none;
      border-top: 8px solid transparent;
      border-right: 10px solid #ff1c26 !important;
      border-bottom: 8px solid transparent;
    }
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 10px solid white;
    border-bottom: 8px solid transparent;
    position: absolute;
    right: -1px;
  }
`;

export const CardText = styled.span`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  @media (max-width: 600px) {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #1C1C1E;
  }
`;

export const Image = styled.img`
    @media (max-width: 600px) {
    &.host-image {
      object-fit: contain;
      width: 100%;
    }
  }
`;

export const NavTextMsg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 13px;
  color: #1c1c1e;
  width: 282px;
  background: #ffffff;
  margin-left: 20px;
  line-height: 22px;
  @media (max-width: 600px) {
    max-width: 355px;
  }
`;

export const NavAction = styled.button`
  position: absolute;
  top: 6px;
  right: 0;
  width: fit-content;
  padding: 0 20px;
  height: 50px;
  color: #ff1c26;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: none;
  text-align: right;
  z-index: 10;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.1px;
  cursor: pointer;
`;

export const EnvironmentBadge = styled.div`
  @media (max-width: 600px) {
    position: absolute;
    z-index: 100000;
    padding: 6px;
    right: 22px;
    top: 10px;
    font-size: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #ff1c26;
    color: red;
    border-radius: 5px;
    text-transform: capitalize;
  }

  @media (min-width: 600px) {
    position: absolute;
    z-index: 100000;
    padding: 6px;
    right: 30px;
    bottom: 10px;
    font-size: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    color: #ffffff;
    border-radius: 5px;
    text-transform: capitalize;
  }
`;
