import { Fragment, useState } from "react";
import styled from "styled-components";
import { getTerminatePayment, numberWithCommas } from "../api/service";
import { usePopup } from "../context";
import { useCalculatedAmount, useInitialisePayment } from "../hooks";
import {
  BankContainer,
  BankDetailsWrapper,
  BankSelectWrapper,
  BankText,
  ChargeWrapper,
  UnstyledButton,
} from "./bank";
import { AppButton } from "./components/app-button";
import { Spacer } from "./components/spacer";
import { TwoText } from "./components/two-text";
import { ErrorWrapper } from "./ussd";
import copy from "../assets/copy.svg";

export const ChargesSection = ({
  payment_option,
  customClick,
  customLoading = false,
  onBack = () => {},
}: {
  payment_option: string;
  customClick?: () => void;
  customLoading?: boolean;
  onBack?: () => void;
}) => {
  const {
    merchantDetails: { account_name, account_number, bank_name, ...others },
    setMerchantDetails,
    setPinValue,
    setIsBankSet,
    setSelectedBank,
    setIsTransactionStarted,
    options,
    setIsTimeLapsed,
  } = usePopup();


  const { loading, onClick } = useInitialisePayment(
    payment_option,
    setMerchantDetails
  );

  const {
    AmountToShow,
    calculateAmountToShow,
    calculatedCharge,
    currency,
    amount,
  } = useCalculatedAmount();
  const [isError, setErrorState] = useState(false);

  const onTimeStopped = async () => {
    setIsTimeLapsed!(true);
    try {
      await getTerminatePayment(options.transactionref, options.key);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <BankContainer>
        <BankText>Confirm order payment details below</BankText>
        <Spacer height={45} />
        <BTableLine style={{ borderTop: "1px solid #00000028" }}>
          <BLineChild>Order Amount </BLineChild>
          <BLineChild>
            {currency ?? "NGN"} {numberWithCommas(Math.ceil(amount))}
          </BLineChild>
        </BTableLine>
        <BTableLine>
          <BLineChild>Bank Charge</BLineChild>
          <BLineChild>
            {currency ?? "NGN "}
            {Math.ceil(calculatedCharge).toFixed(2)}
          </BLineChild>
        </BTableLine>
        <BTableLine style={{ borderBottom: "1px solid #00000028" }}>
          <BLineChild>Total</BLineChild>
          <BLineChild style={{ color: "#29b263" }}>
            {currency ?? "NGN"}{" "}
            {numberWithCommas(Math.ceil(calculateAmountToShow))}
          </BLineChild>
        </BTableLine>
        <Spacer height={45} />
        <AppButton
          type="redBtn"
          disabled={false}
          onClick={() => {
            //use context
            setIsTransactionStarted!(true);
            if (customClick) {
              //props
              customClick();
            } else {
              //use context
              onClick();
            }
          }}
          isBusy={customLoading ? customLoading : loading}
          name="Proceed"
        />
        <Spacer height={15} />
        <UnstyledButton
          onClick={() => {
            setIsBankSet!(false);
            setSelectedBank!({});
            setPinValue!("");
            if (onBack) {
              onBack();
            }
          }}
        >
          Back
        </UnstyledButton>
        <Spacer height={15} />
      </BankContainer>
      {/* <BankSelectWrapper>
        {!isError && (
          <Fragment>
            <BankText>
              Transfer {options?.currency ?? "NGN"}{" "}
              {numberWithCommas(AmountToShow)} to {account_name}
            </BankText>
            <Spacer height={25} />
            <BankDetailsWrapper>
              <TwoText topText="BANK NAME" bottomText={bank_name} icon={""} />
              <TwoText
                topText="ACCOUNT NUMBER"
                bottomText={account_number}
                icon={copy}
              />
              <TwoText
                topText="AMOUNT"
                bottomText={account_number}
                icon={copy}
              />
              <ChargeWrapper>Fee {calculatedCharge}</ChargeWrapper>
            </BankDetailsWrapper>
            <Spacer height={20} />
            <BankText>Use this account for this transaction only</BankText>
            <Spacer height={25} />
            <Timer onStop={() => onTimeStopped()} />
            <Spacer height={30} />
            <AppButton
              type="grayBtn"
              disabled={false}
              onClick={() => {
                //use context
                setIsTransactionStarted!(true);
                if (customClick) {
                  //props
                  customClick();
                } else {
                  //use context
                  onClick();
                }
              }}
              isBusy={loading}
              name="Proceed"
            />
          </Fragment>
        )}
        <ErrorWrapper isError={isError} type="Bank" />
      </BankSelectWrapper> */}
    </>
  );
};


export const BTableLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  padding: 15px 10px;
  border-top: 1px solid #0000001c;
  border-bottom: 1px solid #00000003;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const BLineChild = styled.div`
  font-size: 12px;
  line-height: 20px;
`;
