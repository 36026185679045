import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
 } from "react";
 import {
  BankContainer,
  BankSelectWrapper,
  BankText,
  UnstyledButton,
 } from "./bank";
 
 
 import { usePaymentInputs, PaymentInputsWrapper } from "react-payment-inputs";
 import images from "react-payment-inputs/images";
 import {
  completeCardService,
  numberWithCommas,
  errorParser,
  processCardProxy,
  confirmStatusService,
 } from "../api/service";
 import { useCreateCardAdvice } from "../hooks/useCreateCardAdvice";
 import { Store } from "react-notifications-component";
 import { baseQueryUrl, baseWebhookUrl, cardinalUrl } from "../api/urls";
 import { pk, usePopup } from "../context";
 import { ReactComponent as VpayLogo } from "../assets/vlogo.svg";
 import socketIOClient from "socket.io-client";
 import styled, { css } from "styled-components";
 import { useInitiateCardPayment } from "../hooks/useInitiateCardPayment";
 import { ErrorWrapper, GenericRefErrorWrapper } from "./ussd";
 import axios, { AxiosError } from "axios";
 import { ChargesSection } from "./charge";
 import { useFailedTransaction } from "../hooks/useFailedTransaction";
 import { AppButton } from "./components/app-button";
 import { Spacer } from "./components/spacer";
 import { FailedTransaction } from "./views/failed-transaction";
 import { SuccessfulTransaction } from "./views/sucessful-transaction";
 import { useCalculatedAmount } from "../hooks";

 
 export interface ProcessCardProps {
  cardNumber: string;
  expiredMonth: string;
  expiredYear: string;
  cvv: string;
  cardPin: string;
 }
 
 
 const notify = (type, message) =>
  Store.addNotification({
    insert: "top",
    container: "top-right",
    message,
    type,
    dismiss: {
      duration: 5000,
    },
  });
 
 
 const authIframeStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  zIndex: 1,
  backgroundColor: "white",
  overFlow: "scroll",
  border: "none",
 };
 
 
 interface IsocketData {
  paymentreference: string;
  advicereference: string;
 }
 
 
 function encryptMessage(message: any, publicKey: any) {
  const jsEncrypt = new (window as any).JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
  return jsEncrypt.encrypt(message);
 }
 
 
 export const CARD = ({setisOverLayActive}) => {
  // useInitiateCardPayment initiates the card payment process, 
  // thereby sending the transaction ref to the backend.
  const initresponse = useInitiateCardPayment();
  
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
    meta,
    
  } = usePaymentInputs();

  const advicePayload = useCreateCardAdvice(initresponse);
  
  const [stage, setStage] = useState("card");
  const [shouldHideFrame, setShouldHideFrame] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [socketData, setSocketData] = useState<IsocketData | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtp, setIsOtp] = useState(false);
 
 
  const s3dFromRef = useRef<HTMLFormElement>(null);
  const s3dInputRef = useRef<HTMLInputElement>(null);
 
 
  const responseRef = useRef<any>(null);
  const {
    options: {
      transactionref,
      key,
      customer_service_channel,
      domain,
      txn_charge_type,
      txn_charge,
      customer_logo,
      currency,
    },
    cardError,
    setPaymentPayload,
    setIsFailed,
    isRefUsed,
    setIsTransactionStarted,
    setIsSuccess,
  } = usePopup();
 
  
 
  const sendFailureWebhook = useFailedTransaction();
 
 
  const [isLoading, setIsLoading] = useState(false);
 
 
  const [cardDetails, setCardDetails] = useState<any>({
    cardNumber: "",
    cvc: "",
    expiryDate: "",
  });
  const [pin, setPin] = useState<string>("");
 
 
  const { cardNumber, expiryDate, cvc } = cardDetails;
 
 
  const [otpVal, setOtpVal] = useState("");
 
 
  const [cardResponse, setCardResponse] = useState<any>({});
 
 
  const [authResponse, setAuthResponse] = useState<any>({});
 
 
  const { AmountToShow } = useCalculatedAmount();
 
 
  const validateField = (field: string) => {
    if (field === "" || !field) return false;
    return true;
  };
 
 
  const processCardData: ProcessCardProps = {
    cardNumber: cardNumber.replaceAll(" ", ""),
    cvv: cvc,
    expiredMonth: expiryDate.split(" / ")[0],
    expiredYear: "20".concat(expiryDate.split(" / ")[1]),
    cardPin: pin,
  };
 
 
  const isCardValid = useMemo(() => {

    if (
      meta?.cardType?.type === "visa" ||
      meta?.cardType?.type === "mastercard"
    ) {
      return true;
    }
    return false;

  }, [meta?.cardType?.type]);
 
 
  const formattedErrorText = useCallback(
    (text: string): string => {
      let newText = text;
      if (text === "Object reference not set to an instance of an object.") {
        newText = `Transaction failed. If you have been debited kindly reach out to ${
          customer_service_channel || ""
        } and try again.`;
      }
      return newText;
    },
    [customer_service_channel]
  );
 
 
  const createScript = useCallback(() => {
    const res = responseRef.current;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${res?.responseData?.orgId}&session_id=${res?.responseData?.sessionMerchantID}${transactionref}`;
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseRef.current]);
 
 
  const hideFrame = (id) => {
    const frame: HTMLElement | null = document.getElementById(id);
    frame!.style.display = "none";
  };
 
 
  const confirmTransactionStatus = async () => {
    const requestData = {
      paymentreference: socketData?.paymentreference,
      transactionref,
      adviceReference: socketData?.advicereference,
      isOtp,
      otpVal,
      isLive: domain === "live" ? true : false,
    };

    console.log(requestData, "confirmTransactionStatus2::requestData")

    try {
      const res = await fetch(`${baseQueryUrl()}api/v1/webintegration/cp/s`, {
        //   // url: `${await baseQueryUrl()}api/v1/webintegration/cardpayment/status`,
        method: "POST",
        headers: {
          publickey: key,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const parsedRes = await res.json();

      console.log(parsedRes?.message, "confirmTransactionStatus2::parsedRes", parsedRes);

      
      if (!parsedRes?.status) {
        console.log("Log___false", parsedRes)
        if(parsedRes?.message !== "Taking too long to respond. Please try again after some minutes."){
          setErrorMessage(parsedRes?.message);
          setIsFailed!(true);
          sendFailureWebhook();
          setStage("failed");
        }
    
      } else {
        console.log("Log___true", parsedRes)
        const response = parsedRes?.data?.data;
        if (response?.transactionStatus === "Otp") {
          notify(
            "warning",
            "Token Authentication Failed. Incorrect Token Supplied."
          );
          setStage("otp");
        }
        if (response?.transactionStatus === "Failed") {
          setStage("failed");
          sendFailureWebhook();
          setIsFailed!(true);
          setErrorMessage(response?.message);
        }
        if (response?.transactionStatus === "Successful") {
          const payload = {
            paymentstatus: response?.transactionStatus,
            orderamount: response?.orderamount,
            transactionref: response?.transactionref,
          };
          setPaymentPayload!(payload);
          setIsSuccess!(true);
          setStage("success");
          setIsSuccess!(true);
        }
      }
    } catch (error: any) {
      console.error(error,'Hitting-api/v1/webintegration/cp/s'); 
      if(error.message == "Failed to fetch"){
        setStage("failed");
        sendFailureWebhook();
        setIsFailed!(true);
        setErrorMessage("Time-out");
      }
    }
  };

  const confirmPaymentStatus = async () => {

    try {
      const parsedRes = await confirmStatusService(socketData?.paymentreference ?? cardResponse?.responseData?.paymentReference)
      console.log(parsedRes, 'refStatusrefStatus2');

      console.log(parsedRes, "confirmPaymentStatus2::parsedRes_");

      let response = parsedRes?.data?.responseData
      if (response?.transactionStatus !== "Failed" && response?.transactionStatus !== "Successful") {

        let newintervalID = setInterval(async () => {
          try {
            const resp = await confirmStatusService(
              socketData?.paymentreference ?? cardResponse?.responseData?.paymentReference
            );
            let res = resp?.data?.responseData

            console.log(res, 'response_confirmStatusService2')
            if (res?.transactionStatus === "Failed") {
              setStage("failed");
              sendFailureWebhook();
              setIsFailed!(true);
              setErrorMessage(response?.message);
            }
            if (res?.transactionStatus === "Successful") {
              // const payload = {
              //   paymentstatus: response?.transactionStatus,
              //   orderamount: response?.amountCollected,
              //   transactionref: response?.transactionref,
              // };
              setPaymentPayload!(res);
              setIsSuccess!(true);
              setStage("success");
              setIsSuccess!(true);
            }
          } catch (error: any) {
            console.error(error);
            // setLoading(false);
          }
        }, 10000);

        // setErrorMessage(response?.message);
        // setIsFailed!(true);
        // sendFailureWebhook();
        // setStage("failed");
      } else {
        // const response = parsedRes?.data?.data;
        // if (response?.transactionStatus === "Otp") {
        //   notify(
        //     "warning",
        //     "Token Authentication Failed. Incorrect Token Supplied."
        //   );
        //   setStage("otp");
        // }
        if (response?.transactionStatus === "Failed") {
          setStage("failed");
          sendFailureWebhook();
          setIsFailed!(true);
          setErrorMessage(response?.message);
        }
        if (response?.transactionStatus === "Successful") {
          const payload = {
            paymentstatus: response?.transactionStatus,
            orderamount: response?.amountCollected,
            transactionref: response?.transactionref,
          };
          setPaymentPayload!(response);
          setIsSuccess!(true);
          setStage("success");
          setIsSuccess!(true);
        }
      }
    } catch (error: any) {
      console.error(error,'Hitting-api/v1/webintegration/cp/s');  
      // if(error.message == "Failed to fetch"){
      //   setStage("failed");
      //   sendFailureWebhook();
      //   setIsFailed!(true);
      //   setErrorMessage("Time-out");
      // }
    }
  };
 
 
  const onPayClick = async () => {
    if(cardNumber.length < 6 || !expiryDate || cvc.length < 3){
      return
    }
    
    if (stage === "pin" && !validateField(pin)) {
      return;
    }
 
    if (stage === "pin" && pin.length < 4) {
      return;
    }
    setisOverLayActive(true)
    setIsTransactionStarted!(true)
    try {
      if (!isCardValid && stage !== "pin") {
        setStage("pin");
        setisOverLayActive(false)
      } else {
        const requestPayload = { 
          payload: {
            objCiph: encryptMessage(JSON.stringify(processCardData), pk),
            ref: advicePayload?.responseData?.adviceReference,
            domain,
          },
        };
        setIsTransactionStarted!(true);
        // setIsLoading(true);
        setisOverLayActive(true)
        await processCardProxy(key, requestPayload)
          .then((res: any) => {
            setisOverLayActive(false)
            if (res?.error) {
              // setIsLoading(false);
              setisOverLayActive(false)
              notify(
                "danger",
                "Sorry, we are unable to process your request at the moment. Please wait a little while then try again later."
              );
              console.log("proxy error::", res.error?.response?.data?.message);
              return;
            }
            setCardResponse(res?.data);
            responseRef.current = res?.data;
          })
          .catch((err) => {
            setisOverLayActive(false)
            console.log(err);
          });
      }
    } catch (error: any) {
      notify(
        "danger",
        "Sorry, we are unable to process your request at the moment. Please wait a little while then try again later."
      );
      console.log("proxy catch::", error?.message);
      setisOverLayActive(false)
      // setIsLoading(false);
    }
  };
 
 
  const onOTPClick = async () => {
    if (stage === "otp" && !validateField(otpVal)) {
      return;
    }
 
 
    if (stage === "otp" && otpVal.length < 4) {
      return;
    }
    try {
      setIsLoading(true);
      setIsOtp(true);
      const response = await axios({
        method: "POST",
        // url: `${"http://localhost:8075/"}vpaycardwebhook?paymentreference=${cardResponse?.responseData?.paymentReference}&advicereference=${advicePayload?.responseData?.adviceReference}`,
        url: `${baseQueryUrl()}vpaycardwebhook?paymentreference=${
          cardResponse?.responseData?.paymentReference
        }&advicereference=${advicePayload?.responseData?.adviceReference}`,
      });

      console.log(response,'hitting-vpaycardwebhook_');
      
      setIsLoading(false);
    } catch (error) {
      notify(
        "danger",
        "Sorry, we are unable to process your request at the moment. Please wait a little while then try again later."
      );
      setIsLoading(false);
      console.log(error);
    }
  };
 
 
  // Effect Connecting or Subscribing to the Socket Listener.. This listens for the Payment ref and Advice ref posted
  // ... to the backend socket webhook..
  useEffect(() => {
    (async () => {
      const socket = socketIOClient(`${baseQueryUrl()}`, {
        transports: ["websocket"],
        forceNew: true,
      });
      console.log("SOCKETDATA_UPDATE_04")

 
 
      if (cardResponse?.responseData) {
        socket.emit(
          "WEB_PAYMENT_REF",
          JSON.stringify({
            paymentreference: cardResponse?.responseData?.paymentReference,
          })
        );
      }
 
 
      // const socket = socketIOClient(`${"http://localhost:8075"}`, { transports: ["websocket"], forceNew: true });
      socket.on("WEB_PAYMENT_TRANSACTION_STATUS", (data: any) => {
        console.log("SOCKETDATA_UPDATE_03")
        setSocketData(JSON.parse(data));
      });
 
 
      return () => {
        socket.disconnect();
      };
    })();
  }, [cardResponse]);
 
 
  // Effect Subscribing to the SocketData.
  useEffect(() => {
    (async () => {
      console.log("SOCKETDATA_UPDATE_01")
      if (socketData !== null) {
        console.log("SOCKETDATA_UPDATE_02")
        setStage("loading");
        hideFrame("3dframe");
       let [res, res2] = await Promise.all([confirmTransactionStatus(), confirmPaymentStatus()]);
      console.log(res, res2, 'Both operations resolved');

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);
 
 
  // Effect Subscribing to the message Event coming from the AuthSetup flow.
  useEffect(() => {
    window.addEventListener("message", async (event) => {
      const refVal = responseRef.current;
      setIsLoading(true);
      const url = await cardinalUrl();
      if (event.data && typeof event.data === "string") {
        JSON.parse(event.data);
      }
      if (event.origin === url) {
        createScript();
      const res2 =  await completePayment(
          refVal?.responseData.paymentReference,
          transactionref
        );
        console.log(res2, "COMPLETE_CARD_RESPONSE_01")

      }
      setIsLoading(false);
    });
 
 
    return () => window.removeEventListener("message", () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
 
  // Effect to check response coming after Processing Card, to determine whether to go the Cardinal flow , OTP or Failure
  useEffect(() => {
    if (cardResponse?.responseData?.transactionStatus === "Failed") {
      setStage("failed");
      notify(
        "danger",
        `Transaction Failed. ${formattedErrorText(
          cardResponse?.responseData?.message
        )}`
      );
      setIsFailed!(true);
      setErrorMessage(authResponse?.responseData?.message);
      sendFailureWebhook();
      setIsLoading(false);
    }
    if (
      cardResponse?.message === "Successful" &&
      cardResponse?.responseData?.transactionStatus === "AuthSetup"
    ) {
      setIsLoading(false);
      setStage("bankAuth");
    }
    if (
      cardResponse?.message === "Successful" &&
      cardResponse?.responseData?.transactionStatus === "Otp"
    ) {
      setIsLoading(false);
      notify("success", `${cardResponse?.responseData?.message}`);
      setStage("otp");
    }
    if (
      cardResponse?.message === "Successful" &&
      cardResponse?.responseData?.transactionStatus === "Successful"
    ) {
      setStage("success");
      const payload = {
        paymentstatus: cardResponse?.responseData?.transactionStatus,
        orderamount: cardResponse?.responseData?.amount,
        transactionref: transactionref,
      };
      setIsSuccess!(true);
      setPaymentPayload!(payload);
      setIsSuccess!(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [cardResponse]);
 
 
  // Effect waiting for response from the 3D Cardianal Flow, checking the transaction Status
  useEffect(() => {
    if (authResponse?.responseData?.transactionStatus === "Failed") {
      setStage("failed");
      notify(
        "danger",
        `Transaction Failed. ${formattedErrorText(
          authResponse?.responseData?.message
        )}`
      );
      setIsFailed!(true);
      sendFailureWebhook();
      setErrorMessage(authResponse?.responseData?.message);
      setIsLoading(false);
    }
    if (
      authResponse?.message === "Successful" &&
      authResponse?.responseData?.transactionStatus === "Secure3D"
    ) {
      setIsLoading(true);
      setShouldHideFrame(false);
      const {
        responseData: {
          formData: { url, formData },
        },
      } = authResponse;
      authCompleteTransaction(formData.JWT, url);
    }
 
 
    if (authResponse?.responseData?.transactionStatus === "Otp") {
      setIsLoading(false);
      setStage("otp");
    }
 
 
    if (authResponse?.responseData?.transactionStatus === "Successful") {
      const payload = {
        paymentstatus: authResponse?.responseData?.transactionStatus,
        orderamount: authResponse?.responseData?.amount,
        transactionref: transactionref,
      };
      setPaymentPayload!(payload);
      setStage("success");
      setIsSuccess!(true);
      setIsLoading(false);
      setIsSuccess!(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResponse]);
 
 
  useEffect(() => {
    if (stage === "card") {
      const input = document.getElementById("cvc") as HTMLInputElement;
      if (input) {
        input!.type = "password";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);
 
 
  const handleChangeCardNumber = (e: any) => {
    const { value, name } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };
 
 
  const secure3DTransaction = (jwt: string, url: string) => {
    const form = formRef.current;
    const input = inputRef.current;
    form!.action = url;
    form!.method = "POST";
    input!.value = jwt;
    form!.submit();
   
  };
 
 
  const authCompleteTransaction = (jwt: string, url: string) => {
    const form = s3dFromRef.current;
    const input = s3dInputRef.current;
    form!.action = url;
    form!.method = "POST";
    input!.value = jwt;
    form!.submit();
    setIsLoading(false);
  };
 
 
  const completePayment = async (ref: string, value: string) => {
    try {
      setIsLoading(true);
      const data = {
        paymentReference: ref,
        value,
      };
      const res = await completeCardService(data);
      console.log(res, "COMPLETE_CARD_RESPONSE_02")
      setAuthResponse(res.data);
    } catch (error) {
      console.log(error);
    }
  };
 
 
  const onBankAuthenticateClick = () => {
    setIsLoading(true);
    setIsOtp(false);
    const {
      responseData: {
        formData: { url, formData },
      },
    } = cardResponse;
   
    secure3DTransaction(formData.JWT, url);
  };
 
 
  const onShowCharge = () => {
 
 
    if (
      !validateField(cardNumber) ||
      meta?.erroredInputs?.expiryDate ||
      meta?.erroredInputs?.cvc
    ) {
      return;
    }
 
 
    if (!txn_charge_type && (txn_charge === null || txn_charge === undefined)) {
      onPayClick();
    } else if (
      (txn_charge !== null || txn_charge !== undefined) &&
      txn_charge_type
    ) {
      setStage("showCharge");
    }
  };
 
 
  const verveSVG = useCallback(() => {
    return (
      <svg
        height="35"
        viewBox="0 0 462 161"
        width="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="matrix(0 -1 1 0 .5 160.5)">
          <path
            d="m79.9417 159.8534c-43.9416 0-79.5631-35.6215-79.5631-79.5631 0-43.9436 35.6215-79.5661 79.5631-79.5661s79.5631 35.6225 79.5631 79.5661c0 43.9416-35.6215 79.5631-79.5631 79.5631"
            fill="#ed342b"
          />
          <path
            d="m45.8608 80.2892c40.3403-17.7967 78.0258-30.8457 78.0258-30.8457v-27.2823s-48.3695 16.6053-108.8585 47.45v21.3561c60.489 30.8447 108.8585 47.4489 108.8585 47.4489v-27.2823s-37.6855-13.0479-78.0258-30.8447"
            fill="#fefefe"
          />
          <path
            d="m84.0912 422.9398c0-15.4229-16.6083-16.6073-16.6083-16.6073v33.2116s16.6083-1.1845 16.6083-16.6043m-33.2136 36.7719v-53.3792s-17.7958 1.1844-17.7958 24.9114c0 11.8605 3.5624 23.723 3.5624 23.723l-18.9792 2.3739s-4.7478-11.8625-4.7478-28.4708c0-23.724 11.8635-45.0761 45.0771-45.0761 26.0969 0 42.7042 16.6083 42.7042 40.3323 0 35.5855-35.5865 37.9564-49.8209 35.5855m28.0285-181.5094 19.5859 3.6733s8.606-28.3618-7.3426-51.4141h-78.3437v24.4816h63.6545c7.3416 9.7925 2.4459 23.2592 2.4459 23.2592m5.1851-102.0465c0-15.4198-16.6083-16.6053-16.6083-16.6053v33.2126s16.6083-1.1844 16.6083-16.6073m-33.2136 36.772v-53.3773s-17.7958 1.1855-17.7958 24.9095c0 11.8595 3.5624 23.723 3.5624 23.723l-18.9792 2.3739s-4.7478-11.8635-4.7478-28.4708c0-23.724 11.8635-45.0771 45.0771-45.0771 26.0969 0 42.7042 16.6083 42.7042 40.3323 0 35.5865-35.5865 37.9574-49.8209 35.5865m-8.1295 125.0202c29.8371-12.4312 57.9361-18.6444 57.9361-18.6444l-.003-24.8574s-47.9868 12.4302-87.7493 33.5624v19.8788c39.7625 21.1332 87.7423 33.5634 87.7423 33.5634v-24.8585s-28.089-6.2131-57.9261-18.6443"
            fill="#03435f"
          />
        </g>
      </svg>
    );
  }, []);
 
 
  const cardFlow = useCallback(() => {
    return (
      <Fragment>

        <BankSelectWrapper>
          <BankText
            style={{ margin: "0 auto", marginBottom:"8px" ,fontWeight: 500, width: "100%" }}
          >
            Enter your card details to pay
          </BankText>
          <CardContainer>
            {/* <Spacer height="1px" /> */}
            <PaymentInputsWrapper
              // {...wrapperProps}
 
 
              styles={{
                border: "2px solid red",
                fieldWrapper: {
                  base: css`
                    flex-direction: column;
                    margin-bottom: 1rem;
                    height: 114px;
                  `,
                },
                inputWrapper: {
                  base: css`
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    border: 1px solid #ffffff;
                    box-shadow: none;
                  `,
                  // errored: css`
                  //   border-color: maroon;
                  // `,
                  focused: css`
                    border-color: unset;
                    box-shadow: unset;
                    outline: 2px solid red;
                    outline-offset: 2px;
                    border: 2px solid green;
                    width: 100%;
                  `,
                },
                input: {
                  base: css`
                    color: green;
                  `,
                  errored: css`
                    color: maroon;
                  `,
                  cardNumber: css`
                    width: 15rem;
                  `,
                  expiryDate: css`
                    width: 10rem;
                  `,
                  cvc: css`
                    width: 5rem;
                  `,
                },
                errorText: {
                  base: css`
                    color: maroon;
                  `,
                },
              }}
            >
              {/* <div> */}
              <div
                className="card_number_input"
              >
                {isCardValid ? (
                  <svg {...getCardImageProps({ images })} />
                ) : !isCardValid && cardNumber.length ? (
                  verveSVG()
                ) : null}
                <FlexRow>
                  <CardInput
                    value={cardNumber}
                    {...getCardNumberProps({
                      onChange: (e: any) => handleChangeCardNumber(e),
                    })}
                    placeholder="0000 0000 0000 0000"
                  />
                </FlexRow>
              </div>
 
 
              <FlexRow >
                <CardInput
                  style={{ flex: "50%", width: "50%" }}
                  value={expiryDate}
                  {...getExpiryDateProps({
                    onChange: (e: any) => handleChangeCardNumber(e),
                  })}
                />
                <CardInput
                  style={{ flex: "50%" }}
                  value={cvc}
                  {...getCVCProps({
                    onChange: (e: any) => handleChangeCardNumber(e),
                  })}
                  placeholder="CVV"
                />
              </FlexRow>
 
 
              {/* </div> */}
            </PaymentInputsWrapper>
            <Spacer height={10} />
            <BankContainer>
              <AppButton
                type="redBtn"
                disabled={cardNumber.length < 6 || !expiryDate || cvc.length < 3}
                isBusy={isLoading}
                onClick={() => onPayClick()}
                name={`Pay ${currency ?? "NGN"}  ${numberWithCommas(
                  AmountToShow
                )}`}
              />
            </BankContainer>
          </CardContainer>
        </BankSelectWrapper>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumber, cvc, expiryDate, isLoading]);
 
 
  const renderOTP = useCallback(() => {
    return (
      <Fragment>
        <MyColumnFlex style={{ alignItems: "center" }}>
          <CardInputLabel>Please enter the OTP sent to you</CardInputLabel>
          <CardInput
            onChange={(e) => setOtpVal(e.target.value)}
            value={otpVal}
            style={{
              maxWidth: "200px",
              padding: "0 20px",
              textAlign: "center",
            }}
          ></CardInput>
        </MyColumnFlex>
        <Spacer height={25} />
        <BankContainer>
          {/* <AppButton isBusy={isLoading} onClick={() => completePayment(cardResponse?.responseData?.paymentReference, otpVal)} name="Validate" /> */}
          <AppButton
            type="redBtn"
            disabled={false}
            isBusy={isLoading}
            onClick={() => onOTPClick()}
            name="Validate"
          />
          <Spacer height={15} />
          <UnstyledButton onClick={() => setStage("pin")}>
            Cancel
          </UnstyledButton>
        </BankContainer>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpVal, isLoading, onPayClick]);
 
 
  const renderPin = () => {
    return (
      <Fragment>
        <MyColumnFlex style={{ alignItems: "center" }}>
          <CardInputLabel>Please enter card pin</CardInputLabel>
          <CardInput
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            style={{ maxWidth: "40px", padding: "0 2em" }}
          ></CardInput>
        </MyColumnFlex>
        <Spacer height={25} />
        <BankContainer>
          <AppButton
            type="redBtn"
            disabled={false}
            isBusy={isLoading}
            onClick={() => onPayClick()}
            name="Validate"
          />
          <Spacer height={15} />
          <UnstyledButton onClick={() => setStage("card")}>
            Cancel
          </UnstyledButton>
        </BankContainer>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
 
 
  const renderLoading = useCallback(() => {
    return (
      <Fragment>
        <MyColumnFlex style={{ alignItems: "center" }}>
          {AppCustomLogo(true)}
          <Spacer height={10} />
          <BankText>Please wait while we process your transaction.</BankText>
          <Spacer height={10} />
          <DotLoader />
        </MyColumnFlex>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);
 
 
  const AppCustomLogo = useCallback(
    (loader) => {
      return <VpayLogo className={`${loader && "loading-logo"}`} />;
      // if (customer_logo) {
      //   return (
      //     <MerchantLogo>
      //       <ImageLogo
      //         src={customer_logo}
      //         className={`${loader && "loading-logo"}`}
      //         alt="company-logo"
      //       />
      //     </MerchantLogo>
      //   );
      // } else {
      //   return <VpayLogo className={`${loader && "loading-logo"}`} />;
      // }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [customer_logo]
  );
 
 
  const renderBankAuth = useCallback(() => {
    return (
      <Fragment>
        <MyColumnFlex style={{ alignItems: "center" }}>
          {AppCustomLogo(false)}
          <Spacer height={15} />
          <BankText
            style={{ margin: "0 auto", fontWeight: 500, width: "100%" }}
          >
            Please click the button below to authenticate with your bank
          </BankText>
          <Spacer height={15} />
          <AppButton
            type="redBtn"
            disabled={false}
            isBusy={isLoading}
            onClick={() => onBankAuthenticateClick()}
            name="Authenticate"
          />
          <Spacer height={15} />
          <UnstyledButton onClick={() => setStage("card")}>
            Cancel
          </UnstyledButton>
        </MyColumnFlex>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, isLoading]);
 
 
  const renderUI = () => {
    switch (stage) {
      case "card":
        return cardFlow();
      case "otp":
        return renderOTP();
      case "loading":
        return renderLoading();
      case "pin":
        return renderPin();
      case "bankAuth":
        return renderBankAuth();
      case "showCharge":
        return (
          <ChargesSection
            onBack={() => {
              setIsLoading(false);
              setStage("card");
            }}
            customLoading={isLoading}
            payment_option="card"
            customClick={() => onPayClick()}
          />
        );
      case "success":
        return <SuccessfulTransaction />;
      case "failed":
        return (
          <FailedTransaction
            errorMessage={formattedErrorText(errorMessage)}
            onClose={() => {}}
          />
        );
      default:
        return cardFlow();
    }
  };
 
 
  if (isRefUsed) {
    return <GenericRefErrorWrapper />;
  }
 
 
  return (
    <Fragment>
      {cardError ? (
        <ErrorWrapper type="Card" isError={cardError} />
      ) : (
        renderUI()
      )}
      <iframe
        title="authFrame"
        name="ddc-iframe"
        height="10"
        width="10"
        style={{ display: "none" }}
      >
        {" "}
      </iframe>
      <form
        ref={formRef}
        id="ddc-form"
        target="ddc-iframe"
        method="POST"
        hidden={true}
      >
        <input type="hidden" ref={inputRef} name="JWT" />
      </form>
 
 
      <iframe
        title="secure3D"
        id="3dframe"
        name="s3d-iframe"
        hidden={shouldHideFrame && socketData === null}
        height="10"
        width="10"
        style={authIframeStyles as CSSProperties}
      >
        {" "}
      </iframe>
      <form
        ref={s3dFromRef}
        id="s3d-form"
        target="s3d-iframe"
        method="POST"
        hidden={true}
      >
        <input type="hidden" ref={s3dInputRef} name="JWT" />
      </form>
      {!shouldHideFrame && socketData === null && (
        <BankText style={{ margin: "0 auto", fontWeight: 500, width: "100%" }}>
          <b>Scroll up around the text area to see the OTP input field</b>
        </BankText>
      )}
      {!shouldHideFrame  && (
        <BankText style={{ margin: "0 auto", fontWeight: 500, width: "100%" }}>
          <b>Scroll up-around the text area to see the OTP input field</b>
        </BankText>
      )}
    </Fragment>
  );
 };
 
 
 export const DotLoader = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ff1c26;
  color: #ff1c26;
  box-shadow: 9999px 0 0 -5px #ff1c26;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
 
 
  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ff1c26;
    color: #ff1c26;
  }
 
 
  &::before {
    box-shadow: 9984px 0 0 -5px #ff1c26;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
 
 
  &::after {
    box-shadow: 10014px 0 0 -5px #ff1c26;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }
 
 
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #ff1c26;
    }
    30% {
      box-shadow: 9984px 0 0 2px #ff1c26;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #ff1c26;
    }
  }
 
 
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #ff1c26;
    }
    30% {
      box-shadow: 9999px 0 0 2px #ff1c26;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #ff1c26;
    }
  }
 
 
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #ff1c26;
    }
    30% {
      box-shadow: 10014px 0 0 2px #ff1c26;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #ff1c26;
    }
  }
 `;
 
 
 export const CardContainer = styled.div`
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  row-gap: 1em;
  @media (max-width: 600px) {
    max-width: 100%;
  }
 `;
 export const CardInput = styled.input`
  background: #f8f8fc;
  border-radius: 4px;
  font-size: 14px;
  text-indent: 15px;
  border: none;
  // border-bottom: solid 2px #ccc;
  outline: none;
  height: 52px !important;
  display: inline-block;
  position: relative;
  width: 100%;
  &::placeholder {
    color: #b7b7b9;
    font-size: 14px;
    // padding-left: 15px;
  }
 `;
 export const FlexRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  max-width: 100%;
  width: 282px;
 
 
  @media (max-width: 600px) {
    width: 355px !important;
  }
 
 
  @media (max-width: 370px) {
    width: 282px !important;
  }
 
 
  @media (max-width: 311px) {
    width: 252px !important;
  }
 
 
 
 
 `;
 export const CardInputLabel = styled.p`
  width: fit-content;
  font-size: 0.9em;
 `;
 export const MyColumnFlex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  row-gap: 0.6em;
 `;
 export const ImageLogo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 60px;
  @media (max-width: 600px) {
    &.host-image {
      height: 25px;
    }
  }
 `;
 
 
 export const MerchantLogo = styled.div`
  @media (max-width: 425px) {
    width: 163px;
  }
 `;
 
 
 export const DarkBackground = styled.div.attrs(
  (props: { disappear: boolean }) => props
 )`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.62); /* Black w/ opacity */
 
 
  ${(props) =>
    props.disappear &&
    css`
      display: flex; /* show */
      align-items: center;
      justify-content: center;
    `}
 `;
 
 
 
 